import React from "react";
import { Box } from "@material-ui/core";

const Logo = (props) => {
  return (
    <Box className="logoImg">
      <img src="/images/logo_i.png" alt="Logo" {...props} />
    </Box>
  );
};

export default Logo;
