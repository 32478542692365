import React, { lazy } from "react";
// import HomeLayout from "../layouts/HomeLayout";

const HomePage = lazy(() => import("../views/pages/Home"));
const HomeLayout = lazy(() => import("../layouts/HomeLayout"));

const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    element: HomePage,
  },

  {
    exact: true,
    path: "/404",
    element: lazy(() => import("../views/errors/NotFound")),
  },
];

export default routes;
