import React from "react";

import { Box, Container, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#D63A3A",
    "& .mainBox": {
      flexWrap: "wrap",
      padding: "34px 0px",
      borderBottom: "3px solid rgba(200, 200, 200, 0.70)",
      alignItems: "self-start",
      "& p": {
        color: "#fff",
        lineHeight: "36px",
        fontWeight: 600,
      },
      "& .joinBox": {
        marginTop: "35px",
        display: "flex",
        alignItems: "center",
        gap: "21px",
        "& img": {
          height: "30px",
          weight: "30px",
        },
      },
      "& .disclamerBox": {
        maxWidth: "320px",
        "& p": {
          marginTop: "12px",
          fontWeight: 500,
          lineHeight: "23px",
        },
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.footerSection}>
      <Container maxWidth="lg" style={{ paddingBottom: "42px" }}>
        <Box className="mainBox displaySpaceBetween">
          <Box>
            <Typography variant="h2">REPOTOKEN</Typography>
            <Box mt={2}>
              <Typography variant="body2">ABOUT US</Typography>
              <Typography variant="body2">REPO BOT</Typography>
              <Typography variant="body2">COMMUNITY REPO FUND</Typography>
              <Typography variant="body2">TOKENOMICS</Typography>
              <Typography variant="body2">ROADMAP</Typography>
              <Typography variant="body2">HOW TO BUY</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="h2">JOIN US</Typography>
            <Box className="joinBox">
              <img src="images/blockIcon.svg" />
              <img src="images/horseIcon.svg" />
              <img src="images/network.png" />
              <img src="images/Twitter.png" />
              <img src="images/Telegram App.png" />
            </Box>
            <Box mt={2} className="disclamerBox">
              <Typography variant="h2">Disclaimer</Typography>
              <Typography variant="body2">
                $REPO is a meme coin devoid of intrinsic value or financial
                projections. Kindly acknowledge the associated risks and conduct
                thorough research before considering an investment.
              </Typography>
              <Typography variant="body2" style={{ marginTop: "20px" }}>
                X@GetRepoToken.com
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
