import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";

import { RouterProvider } from "react-router-dom";
import router from "./router";
// import AuthContext from "./context/Auth";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "./theme";
// import { Toaster } from "react-hot-toast";
import { mainnet } from "wagmi/chains";
import {
  injectedWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
const projectId = "b8ce9552362a641a50d797398e10b7f3";

function App() {
  const theme = createTheme();
  const toasterOptions = {
    style: {
      fontSize: "16px", // Adjust the font size here
      wordBreak: "break-word",
      // fontFamily: "Arial",
    },
  };

  //rainbowkit and wagmi connectors implementation for establishing web3 connectivityyy
  const { chains, publicClient } = configureChains(
    [mainnet],
    [publicProvider()]
  );
  const connectors = connectorsForWallets([
    // ...wallets,
    {
      groupName: "tokensnifferbot.io",
      wallets: [
        injectedWallet({ projectId, chains }),
        metaMaskWallet({ projectId, chains }),
        trustWallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: false,
    connectors,
    publicClient,
  });

  return (
    <div className="bg-primary w-full overflow-hidden">
      {" "}
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          coolMode
          modalSize="compact"
          theme={darkTheme({
            accentColor: "#D63A3A",
            accentColorForeground: "#fff",
            borderRadius: "large",
            fontStack: "system",
            overlayBlur: "small",
            fontWeight: "500px",
          })}
        >
          {/* <ConnectButton /> */}
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {/* <Toaster position="top-center" toastOptions={toasterOptions} /> */}
              <RouterProvider router={router} />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;

// import React, { Suspense, Fragment } from "react";
// import { Router, Switch, Route } from "react-router-dom";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";
// import { routes } from "src/routes";
// import { createBrowserHistory } from "history";
// import { ThemeProvider } from "@material-ui/core";
// import { createTheme } from "src/theme";
// import PageLoading from "./component/PageLoading";

// const history = createBrowserHistory();

// function App() {
//   const theme = createTheme();

//   return (
//     <div className="App">
//       <ThemeProvider theme={theme}>
//         <MuiPickersUtilsProvider utils={MomentUtils}>
//           <Router history={history}>
//             <RenderRoutes data={routes} />
//           </Router>
//         </MuiPickersUtilsProvider>
//       </ThemeProvider>
//     </div>
//   );
// }

// export default App;

// function RenderRoutes(props) {
//   return (
//     <Suspense fallback={<PageLoading />}>
//       <Switch>
//         {props.data.map((route, i) => {
//           const Component = route.component;
//           // const Guard = route.guard ? AuthGuard : Fragment
//           const Layout = route.layout || Fragment;
//           return (
//             <Route
//               exact={route.exact}
//               key={i}
//               path={route.path}
//               render={(props) => (
//                 <Layout>
//                   {route.routes ? (
//                     <RenderRoutes data={route.routes} />
//                   ) : (
//                     <Component {...props} />
//                   )}
//                 </Layout>
//               )}
//             />
//           );
//         })}
//       </Switch>
//     </Suspense>
//   );
// }
